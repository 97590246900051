import { render, staticRenderFns } from "./OrganismCertificationsV2.vue?vue&type=template&id=67b49c2a&"
import script from "./OrganismCertificationsV2.vue?vue&type=script&lang=js&"
export * from "./OrganismCertificationsV2.vue?vue&type=script&lang=js&"
import style0 from "./OrganismCertificationsV2.vue?vue&type=style&index=0&id=67b49c2a&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {MoleculesCommonMoleculeCertifications: require('/app/components/molecules/common/MoleculeCertifications.vue').default})
